import '../productFilter/filterProduct.css'
import React , {useEffect} from 'react';

import { Link, useNavigate } from 'react-router-dom'
const CoffeProdcut = ({ image, name, des,data,keyof }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    const navigate=useNavigate();
    return (

        <div onClick={()=>navigate('/coffeeDetails',{
            state:{
                data,
                key:keyof
            }
        })} data-menu="data" className="single news-Slide-up" id="cardHover"  >
            <img src={image} alt="" className='singleCard' />
            <div className='newsCaption'>
                <h2 className='newsCaption-title'>
                    {name}
                </h2>
              
            </div>
        </div>
    );
}

export default CoffeProdcut;
import React, { useState ,useEffect} from 'react'
import "./navbar.css"
import {Link} from 'react-router-dom'
import logogded1 from './logogded1.png'

import eurologo2 from './eurologo2.png'
import { useNavigate , useLocation  } from 'react-router-dom'
import { RiMenu3Line, RiCloseLine } from "react-icons/ri"
import {BiPhoneCall} from 'react-icons/bi'
import {HiOutlineMail} from 'react-icons/hi'
import {FaFacebook} from 'react-icons/fa'
import {AiFillTwitterCircle,AiFillInstagram} from 'react-icons/ai'
import BlackHead from './blackheader/BlackHead'
import Buttonn from './Buttonn'


const Navbar = (props) => {
  const [scrollPosition, setScrollPosition] = useState(0);
 

  const [toggleMenu, setToggleMenu] = useState(false)
  useEffect(() => {
    function handleScroll() {
      setScrollPosition(window.scrollY);
    }

    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
/*
 <p  className="dropbtnn" >
        <Link to='/products'  className='likis' onClick={()=> setToggleMenu(false)}>Petrol Products</Link></p>
      <p><Link to='/coffee' className='likis' onClick={()=> setToggleMenu(false)}>Espresso Coffee</Link></p>
      <p><Link to='/aboutus' className='likis' onClick={()=> setToggleMenu(false)}>About Us</Link></p>
      <p><Link to='/contactus'  className='likis' onClick={()=> setToggleMenu(false)}>Contact Us</Link></p>
            <h3><a href='/' className='doe' onClick={()=> {setToggleMenu(false)}   }>Home</a></h3>

      */

  const Menua = () => (  

    <>
      <h3><Link to='/'  className='doe' onClick={()=> setToggleMenu(false)}>Home</Link></h3>

      <h3><Link to='/products'  className='doe' onClick={()=> setToggleMenu(false)}>Petrol Products</Link></h3>
      <h3><Link to='/coffee' className='doe' onClick={()=> setToggleMenu(false)}>Espresso Coffee</Link></h3>
      <h3><Link to='/aboutus' className='doe' onClick={()=> setToggleMenu(false)}>About Us</Link></h3>
      <h3><Link to='/contactus'  className='doe' onClick={()=> setToggleMenu(false)}>Contact Us</Link></h3>

  
     
    </>
  )
  const navbarStyle = {
    backgroundColor: scrollPosition > 0 ? '#fff' : 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'sticky',
    top: '0',
    zIndex: '999',
    
  };
  const [showNavbarOne, setShowNavbarOne] = useState(true) ;
  const history = useNavigate();
  const handleButtonClick = () => {
    setShowNavbarOne(!showNavbarOne);
     history('/');
     };

   

  return (
    <>
      
    <div className="gpt3__navbar  navShadow" style={navbarStyle} >
        
      <div className="gpt3__navbar-linkss ">
        <div className="gpt3__navbar-links_logo">
          <img src={logogded1} alt="GPT3 Logo" />          <img src={eurologo2} id='sora' alt="GPT3 Logo" />

        </div>
        <div className="gpt3__navbar-links_container">
          <Menua />
        </div>
        <div className="gpt3__navbar-wrapper">

       
        
          <div className="gpt3__navbar-menu" >
            {toggleMenu
              ? <RiCloseLine color="black" size={27} onClick={() => setToggleMenu(false)} />
              : <RiMenu3Line color="black" size={27} onClick={() => setToggleMenu(true)} />
            }
            {toggleMenu &&
              <div className="gpt3__navbar-menu_container " >
                  <Menua onClick={()=> setToggleMenu(false)}/>
                <div className="gpt3__navbar-menu_container-links-sign">
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Navbar
import React , {useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import { FaFacebook } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { BsFillGeoAltFill } from 'react-icons/bs';
import './ContAra.css';
import { BsBuilding } from 'react-icons/bs';
import { BsPersonSquare } from 'react-icons/bs';

const ContactUsAra = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    return (
      <div className='contactarabic'>
      <section className="Productscontact ">
      <p>تـواصل مـعنا</p>
      <p>
     Euro 10 international تواصل مـع 
      </p>
    </section>
    <Container>
   
      <Row>
        
      <Col sm={6} className='mapcol'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2549.7895611439803!2d31.472428014238716!3d30.219882481818814!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14580533570535ff%3A0xcd031bb8b15a5f53!2z2LPZhdmK2LHYqSDZhdmI2LPZiiwgQWwgT2JvdXIsIEFsIFFhbHl1YmlhIEdvdmVybm9yYXRl!5e1!3m2!1sen!2seg!4v1679848884168!5m2!1sen!2seg" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </Col>

          <Col sm={6}>
          <Alert variant="success text-center" id='ali'>
            <Alert.Heading>طـــــرق التواصل لـدينا</Alert.Heading>
          </Alert>
          <Alert variant="dark" id='arabic-alert1' >

          <span id='facearabic' > <FaFacebook/> <a href='https://www.facebook.com/Euro10INT'>    صـفحتنا علي الفيس بوك </a></span>

          <br/>    
          <span id="facearabic"> <HiOutlineMail/>  <a href="mailto:info@euro10int.com">     info@euro10int.com </a></span><hr/>

<span id="facearabic"><BsBuilding/>  <a href="tel:+201111495011"> مـكتب : 01111495011</a> </span><br></br>
<span id="facearabic"> <BsBuilding/> <a href="tel:+201151555865"> مــكتب : 01151555865</a> </span><hr/>

<span id="facearabic"> <BsFillGeoAltFill/> <a href="#" id='loca'> 10 برج الصفوة , شارع سميرة موسي . الحي الخامس , العبور </a> </span>


          </Alert>
          
          </Col>
      </Row><hr/>
      <Row>
        <Col sm={6}>
          <Alert variant="danger  "id='sales' >
          <span id="faceid"> <BsPersonSquare/> <a href="tel:+201103830086">مـبيعات : 01103830086</a> </span><br></br>
          <span id="faceid"> <BsPersonSquare/>  <a href="tel:+201111995801">مـبيعات : 01111995801</a>  </span><br></br>
          <span id="faceid">  <BsPersonSquare/> <a href="tel:+201004824991">مـبيعات : 01004824991</a></span><br></br>
          </Alert>

        </Col>
        <Col sm={6}>
        <Alert variant="success"id='sales' >
          <span id="faceid"> <BsPersonSquare/> <a href="https://web.whatsapp.com/send?phone=+201103830089&text=" > واتس اب : 01103830089 </a> </span><hr/>
          <span id="faceid"> <BsPersonSquare/> <a href="https://web.whatsapp.com/send?phone=+201103830087&text=">واتس اب : 01103830087 </a> </span><br/>
          </Alert>
          </Col>
      </Row><br/>
      <Row>
      <img src={"https://i.ibb.co/HGxwkYP/back3.jpg"} id='contactimg'></img>
      </Row>
      </Container>
</div>

  );
}


export default ContactUsAra;

import React from 'react'
import './about.css';
import { Fade } from 'react-reveal';
import ph1 from './about1.jpg';
import ph2 from './about2.jpg';
import ph3 from './about3.jpeg';
import aboutCardsar from "../../data/db";
const Aboutar = () => {
  return (
    <div>

      <div className="aboutSection container">
        
        <div className='aboutCard1' >
<img src={ph1}alt="" />
<div className="aboutCard-content">
    <p style={{fontSize:"20px",fontWeight:"900",direction:'rtl',textAlign:'center'}}>ما هـو الـ "SAE" ?</p>
    <p style={{direction:'rtl',textAlign:'center'}} > درجة لزوجة زيوت المحرك يتم تحديدها بشكل عام بواسطة معيار SAE J 300 </p>
   
</div>

        </div>
        <div className='aboutCard2' >
<img src={ph2}alt="" />
<div className="aboutCard-content">
    <p style={{fontSize:"20px",fontWeight:"900",direction:'rtl',textAlign:'center'}}>ما هو الدور الذي يلعبه زيت المحرك في السيارة؟</p>
    <p style={{direction:'rtl',textAlign:'center'}}>زيت المحرك له وظائف مهمة في السيارة: يقلل الاحتكاك بين المكونات</p>
   
</div>

        </div>
        <div className='aboutCard2' >
<img src={ph3}alt="" />
<div className="aboutCard-content">
    <p style={{fontSize:"20px",fontWeight:"900",direction:'rtl',textAlign:'center'}}>سطح نظيف في زيوت المحرك </p>
    <p style={{direction:'rtl',textAlign:'center'}}>في الآونة الأخيرة ، تم تقديم تقرير إلى إحدى الصناعات من قبل أحد المختبرات المرجعية للصناعة في الدولة ، يفيد بأن المستوى النظيف لزيت المحرك الذي تستخدمه هو 8 على مقياس NAS ، وهو ملوث في هذا الصدد. يوصى بتحضير الزيت بمستوى نظيف 6.</p>
   
</div>

        </div>
    
       </div>


    </div>
  )
}

export default Aboutar
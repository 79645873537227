import React, { useState ,useEffect , useRef} from 'react'

import "./navbararabic.css"
import {Link} from 'react-router-dom'
import logogded1 from './logogded1.png'
import eurologo2 from './eurologo2.png'

import { RiMenu3Line, RiCloseLine } from "react-icons/ri"
import {BiPhoneCall} from 'react-icons/bi'
import {HiOutlineMail} from 'react-icons/hi'
import {FaFacebook} from 'react-icons/fa'
import {AiFillTwitterCircle,AiFillInstagram} from 'react-icons/ai'
import BlackHead from './blackheader/BlackHead'

  const Navbararabic = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
   

    const [toggleMenu, setToggleMenu] = useState(false)
    useEffect(() => {
      function handleScroll() {
        setScrollPosition(window.scrollY);
      }
    
      window.addEventListener('scroll', handleScroll);
    
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
    const Menu = () => (  
        /* 
          <h4><Link to='/contactusarabic' className='liks' onClick={()=> setToggleMenu(false)}>تواصل معنا</Link></h4>
        <h4><Link to='/aboutus-ar' className='liks' onClick={()=> setToggleMenu(false)}>من نـحن ؟</Link></h4>
        <h4><Link to='/coffeear' className='liks' onClick={()=> setToggleMenu(false)}>منتجات القهوة</Link></h4>
  
        <h4  className="dropbtnn" >
          <Link to='/Productoilar' className='liks' onClick={()=> setToggleMenu(false)} >منتجات الزيت</Link></h4>
  
        <h4><Link to='/Homear' className='liks' onClick={()=> setToggleMenu(false)}>الصفحة الرئيسية</Link></h4>
        */
      <>
        <p><Link to='/contactusarabic' className='liks' onClick={()=> setToggleMenu(false)}>تواصل معنا</Link></p>
        <p><Link to='/aboutus-ar' className='liks' onClick={()=> setToggleMenu(false)}>من نـحن ؟</Link></p>
        <p><Link to='/coffeear' className='liks' onClick={()=> setToggleMenu(false)}>منتجات القهوة</Link></p>
  
        <p  className="dropbtnn" >
          <Link to='/Productoilar' className='liks' onClick={()=> setToggleMenu(false)} >منتجات الزيت</Link></p>
  
        <p><Link to='/Homear' className='liks' onClick={()=> setToggleMenu(false)}>الصفحة الرئيسية</Link></p>
        <div className="gpt3__navbar-menu_container-links-sign">
                    </div>
      </>
    )
    const navbarStyle = {
      backgroundColor: scrollPosition > 0 ? '#fff' : 'transparent',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'sticky',
      top: '0',
      zIndex: '999',
    };
    
   
    
    return (
      <>
       
      <div className="gpt3__navbar  navShadow" style={navbarStyle} >
          
        <div className="gpt3__navbar-links ">
          <div className="gpt3__navbar-links_logo">
            <img src={logogded1} alt="GPT3 Logo" />          <img src={eurologo2} id='sora' alt="GPT3 Logo" />
  
          </div>
          <div className="gpt3__navbar-links_container">
            <Menu />
          </div>
          <div className="gpt3__navbar-wrapper">
  
         
          
            <div className="gpt3__navbar-menu" >
              {toggleMenu
                ? <RiCloseLine color="black" size={27} onClick={() => setToggleMenu(false)} />
                : <RiMenu3Line color="black" size={27} onClick={() => setToggleMenu(true)} />
              }
              {toggleMenu &&
                <div className="gpt3__navbar-menu_container " >
                    <Menu />
                  <div className="gpt3__navbar-menu_container-links-sign">
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }
  
  export default Navbararabic
  
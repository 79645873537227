import React, {useState , useEffect} from 'react';
import './App.css';
import Navbar from './component/Navbar';
import Head from './component/headerSlide/header';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './component/footer/footer';
import ProductDetails from './pages/productDetails/details'
import FilterProContex from './pages/productFilter/filterProduct';
import ProductPage from './pages/products/product'
import {  Routes, Route } from "react-router-dom";
import ScrollToTop from './component/scrollUp/scrollup';
import FilterProPetro from './pages/productFilter/filterProductPetro';
import DetailsPetro from './pages/productDetails/detailsPetro';
import Coffee from './pages/coffeePage/coffee';
import FilterCoffee from './pages/coffeePage/filterCoffee';
import CoffeeDetails from './pages/coffeePage/coffeeDetails';
import BlackHead from './component/blackheader/BlackHead';

import ContactUs from './pages/contactus/ContactUs';
import AboutUs from './component/aboutus/AboutUs';
import AboutUsArab from './component/aboutus/AboutUsArab';

import ContArab from './component/contarab/ContArab';
import ContactUsAra from './pages/contactus/ConatctUsAra';
import Navbararabic from './component/Navbararabic';
import Buttonn from './component/Buttonn';
import { useNavigate  } from 'react-router-dom';
import Heroh from './component/Heroh';
import Footerarabic from './component/footer/Footerarabic';
import CoffeePRoArabic from './pages/coffeePage/CoffeePRoArabic';
import Coffeearabic from './pages/coffeePage/Coffeearabic';
import ProductAra from './pages/products/ProductAra';
import FilterProductar from './pages/productFilter/FilterProductar';
import Headerara from './component/headerSlide/Headerara';

function App(props) {

  const [showNavbarOne, setShowNavbarOne] = useState(true) ;
  const history = useNavigate();
 
  const handleArabicClick = () => {
       setShowNavbarOne(false);
     
        history('/Homear')
  };
  const handleEngClick = () => {
    setShowNavbarOne(true);
  
     history('/')
};


  return (
    
    <div className="App">
      <Heroh/>
      <button style={{background:"#3b3b3b", width:"50%" , color:'white'}} onClick={handleArabicClick}>  <img width={30}
          src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYIHu55WRTv1hM1JHD0J9pyAd8D-NV_EgPm8VOqgpC&s"}
        />   Arabic</button>
      <button style={{background:"#3b3b3b", width:"50%" , color:'white'}} onClick={handleEngClick}>
      <img width={30}
          src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdHL_oeS5-VW0_32SVCPd3W9ZYOMjZq2iwN4gqLTJA&s"}
        />   English</button>

        {showNavbarOne?<Navbar/>:<Navbararabic/>}
        <ScrollToTop/>
        <Routes>
          <Route  path="/" element={<Head/>} />
          <Route path="/Homear" element={<Headerara />} />
          <Route path="/products" element={<ProductPage />} />
          <Route path="/filterproductContex" element={<FilterProContex/>}/>
          <Route path="/filterproductPetro"  element={<FilterProPetro/>}/>
          <Route path="/productdetails" element={<ProductDetails/>} />
          <Route path="/productdetailsPetro" element={<DetailsPetro/>}/>
          <Route path="/coffee"  element={<Coffee/>}/>
          <Route path="/contactusarabic" element={<ContactUsAra />} />
          <Route path="/aboutus-ar" element={<AboutUsArab />} />
          <Route path="/coffeeproductar" element={<CoffeePRoArabic />} />
          <Route path="/coffeear" element={<Coffeearabic />} />
          <Route path="/Productoilar" element={<ProductAra />} />
          <Route path="/productsar" element={<FilterProductar />} />

          
          <Route path="/filterproductCoffee" element={<FilterCoffee/>}/>
          <Route path="/coffeeDetails" element={<CoffeeDetails/>}/>
          <Route path="/contactus" element={<ContactUs/>}/>
          <Route path="/aboutus" element={<AboutUs/>}/>
          <Route path="/cont" element={<ContArab/>}/>


          </Routes>
          {showNavbarOne?<Footer/>:<Footerarabic/>}

          
       

    </div>
  );
}

export default App;

import React , {useState} from 'react';
import "./navbar.css"
import {BiPhoneCall} from 'react-icons/bi'
import {HiOutlineMail} from 'react-icons/hi'
import {FaFacebook} from 'react-icons/fa'
import {AiFillTwitterCircle,AiFillInstagram} from 'react-icons/ai'
import BlackHead from './blackheader/BlackHead'
import Buttonn from './Buttonn'
import { useNavigate } from 'react-router-dom';
const Heroh = (props) => {
    
    return (
        <div className="heroHeader ">
        <div className="mail">
        <span><BiPhoneCall size={"24px"} style={{marginRight:"5px"}}/> <a href="tel:+201111495011" id='mail'> +201111495011</a> </span> 
            <span><HiOutlineMail size={"23px"} style={{marginRight:"5px"}}/> <a href="mailto:info@euro10int.com" id='mail'>info@Euro10int.com</a></span>
        </div>  
        <div className="socialIcons">
         
        <span>  <a href='https://www.facebook.com/Euro10INT'><FaFacebook  /> </a></span>
        <span>  <a href='https://www.instagram.com/covim_egy/?igshid=NTc4MTIwNjQ2YQ%3D%3D' ><AiFillInstagram /> </a></span>
       
          
        </div>
           </div>
    );
}

export default Heroh;

import React , {useEffect} from 'react';
import './AboutUsArab.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    return (
        <div className='aboutus'>
             <section className="Productss ">
      <p>مــن نـحن؟</p>
      <p>
      اعرف اكثر عن شركـتنا
      </p>
    </section>
    <Container >
      <Row>
        <Col sm={6}>
          
        <div id="containerAnimation" class="container-bottom-to-upp" >
             <p className='top-to-bottomm'>
             <Alert variant="secondary">
      <Alert.Heading > <Alert variant="dark">رؤيــتنا</Alert> </Alert.Heading>
      <p >
      نحن واحدة من الشركات الرائدة في صناعة زيوت المحركات والتركيز على رضا العملاء عن جودة المنتج والخدمة ، فقد بدأت أنشطتها في عام 2020.</p>
      <hr>
      </hr>
      <Alert.Heading > <Alert variant="dark">نـحن</Alert> </Alert.Heading>

      <p >Euro 10 international هي مجموعة شركات زيوت المحركات الأسرع نموًا في القاهرة الشرق الأوسط ، حيث تركز أنشطتنا على المنتجات البترولية وقهوة الإسبريسو في مصر.
خلال السنوات القليلة الماضية ، تم توسيع شبكة التوزيع العالمية للتأكد من توفر منتجاتنا عالميًا.</p>
      </Alert>
             </p>
              </div>

        </Col>
        <Col sm={6}>
        <div id="containerAnimation" class="container-overFlowVisible" >  
         <img class="left-to-right" src='https://i.ibb.co/VMQnPDC/back3.jpg'></img>
          </div>


        </Col>
      </Row>
      </Container>
        </div>
    );
}

export default AboutUs;

const aboutCards=[
    {
        id:1,
        img:require('./about1.jpg'),
        header:"What is SAE?",
        content:"We have said that the degree of viscosity of engine oils is generally determined by"
    },
    {
        id:2,
        img:require('./about2.jpg'),
        header:"What role does engine oil play in a car?",
        content:"Engine oil has important functions in the car: Reduces friction between components The engine is"
    },
    {
        id:3,
        img:require('./about3.jpeg'),
        header:"Clean surface in engine oils",
        content:"Recently, a report was given to one of the industries by one of the country's"
    }

]
export default aboutCards ;

export const aboutCardsar=[
    {
        id:10,
        img:require('./about1.jpg'),
        header:"ما هـو الـSAE",
        content:"We have said that the degree of viscosity of engine oils is generally determined by"
    },
    {
        id:21,
        img:require('./about2.jpg'),
        header:"What role does engine oil play in a car?",
        content:"Engine oil has important functions in the car: Reduces friction between components The engine is"
    },
    {
        id:31,
        img:require('./about3.jpeg'),
        header:"Clean surface in engine oils",
        content:"Recently, a report was given to one of the industries by one of the country's"
    }

]


export const productTypes=[

    {
        id:1,
        logo:require('./icon1.png'),
        name:"Contex Products"
    },
    {
        id:2,
        logo:require('./lion1.jpg'),
        name:"Petro S  Products "
    },
  
]

// src/components/OtherComponent.js
import { useNavigate } from 'react-router-dom';
import {React , useState} from 'react';
import './btn.css'
function Buttonn(props) {
  
  const { handleClick, label } = props;
  return (
    
    <button className='.btn-grad' onClick={handleClick}>{label}</button>
   
  );
}

export default Buttonn;

//const [showNavbarOne, setShowNavbarOne] = useState(true) ;
//const history = useNavigate();

//const handleButtonClick = () => {
 // setShowNavbarOne(!showNavbarOne);
 // history('/');

//};